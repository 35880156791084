var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "liten-then-choose-words-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.allImgList.length,
              currentIndex: _vm.currentStarIndex,
            },
          }),
          _c("div", { staticClass: "choose-img-area" }, [
            _c("div", { staticClass: "img-area" }, [
              _c(
                "div",
                { staticClass: "img-content" },
                _vm._l(
                  _vm.allImgList[_vm.currentIndex],
                  function (item, index) {
                    return _c("img", {
                      key: index + "img",
                      staticClass: "img-item",
                      class: {
                        active: index === _vm.chooseImgIndex,
                        isCorrect:
                          item.id === _vm.answerList[_vm.currentIndex] &&
                          index === _vm.chooseImgIndex,
                      },
                      attrs: { src: item.bgImg, alt: "" },
                      on: {
                        click: function ($event) {
                          return _vm.handleChooseImg(item.id, index)
                        },
                      },
                    })
                  }
                ),
                0
              ),
            ]),
            _c("div", { staticClass: "trumpet-area-right" }, [
              _c(
                "span",
                {
                  directives: [{ name: "throttle", rawName: "v-throttle" }],
                  staticClass: "trumpet-area",
                  class: { lessononehover: _vm.isClickAudio },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.playPinyinAudio.apply(null, arguments)
                    },
                    mouseenter: function ($event) {
                      return _vm.hoverImg(1, "hover")
                    },
                    mouseleave: function ($event) {
                      return _vm.hoverImg(1, "")
                    },
                  },
                },
                [
                  _c("AudioPlayer", {
                    attrs: {
                      isPlay: _vm.isStartPlaying,
                      audioUrl: _vm.songUrl,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm.titleInfo
            ? _c("div", { staticClass: "title-area" }, [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                  ),
                  _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                    _vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " "),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }