<template>
  <div class="liten-then-choose-words-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <Star
        :totalStarNumber="allImgList.length"
        :currentIndex="currentStarIndex"
      />
      <!-- page  select img-->
      <div class="choose-img-area" >
        <div class="img-area">
          <div class="img-content">
            <img
              v-for="(item, index) in allImgList[currentIndex]"
              :src="item.bgImg"
              alt=""
              :key="index + 'img'"
              class="img-item"
              :class="{
                active: index === chooseImgIndex,
                isCorrect:
                  item.id === answerList[currentIndex] &&
                  index === chooseImgIndex,
              }"
              @click="handleChooseImg(item.id, index)"
            />
          </div>
        </div>
        <div class="trumpet-area-right">
          <span
            class="trumpet-area"
            @click.stop="playPinyinAudio"
            @mouseenter="hoverImg(1, 'hover')"
            @mouseleave="hoverImg(1, '')"
            :class="{ lessononehover: isClickAudio }"
            v-throttle
          >
            <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
          </span>
        </div>
      </div>
      <div class="title-area" v-if="titleInfo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import Star from "../Star";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools.js";
export default {
  props: {
    allImgList: {
      type: Array,
      require: true,
    },
    trumpetImg: {
      type: String,
      require: true,
    },

    answerList: {
      type: Array,
      require: true,
    },
     chooseHanziList: {
      type: Array,
      require: true,
    },
    titleInfo: {
      type: Object,
      require: false,
    },
  },
  components: {
    Star,
    PageButton,
    AudioPlayer,
  },

  data() {
    return {
      isClickAudio:false,
      isStartPlaying:null,
      songUrl:null,
      lockIndex: 0,
      insertIndex: 0,
      chooseImgIndex: null,

      imgSocketInfo: {},

      currentIndex:0,
      currentStarIndex: 0,

      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  created() {
    // startConfetti();
  },
  mounted() {
    this.$bus.$on("clickChoosedImg", ({ id, index }) => {
      this.handleChooseImg(id, index, true);
    });
    this.$bus.$on("clickChoosedHanzi", ({ id, index }) => {
      this.handleChooseHanzi(id, index, true);
    });
  },
  watch: {
    // 
    imgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003001,
          data: { value },
          text: "ListenThenChooseWordsGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    hoverImg(id) {
      this.isClickAudio = !this.isClickAudio;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    playPinyinAudio() {
       this.isStartPlaying = Math.random();
      // if (this.callList[this.steps].hanzi) {
      //   this.songUrl = this.callList[this.steps].hanzi;
      // } else {
        this.songUrl = this.chooseHanziList[this.currentIndex].audioSrc
      // };
    },
    handleChooseImg(id, index, isFromSocket=false) {
      if (!isFromSocket) {
        this.imgSocketInfo = {
          id,
          index,
          value: Math.random(),
        };
      }
      console.log(id);
      this.chooseImgIndex = index;
      if (id === this.answerList[this.currentIndex]) {
        playCorrectSound(true, false);
        setTimeout(() => {
        this.currentIndex++
          this.lockIndex++;
        this.currentStarIndex++;
          this.checkToNext();
      this.chooseImgIndex=null;
        }, 1000);
      } else {
        playCorrectSound(false);
      }

    },
    checkToNext() {
      if (
        this.lockIndex >=
        this.allImgList.length
      ) {
        this.nextPart();
      }
    },
    nextPart() {
      setTimeout(() => {
        if (this.currentIndex >= this.allImgList.length) {
          this.currentIndex = this.allImgList.length - 1;
          startConfetti();
          playCorrectSound();
        } else {
          playCorrectSound(true, false);
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.liten-then-choose-words-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f7f4e0;
    border-radius: 58px;

    .choose-img-area {
      height: 100%;
      display: flex;
      align-items: center;
      .img-area {
        margin-left: 22%;
        flex: 6;
        display: flex;
        justify-content: center;
        align-items: center;
        .img-content {
          width: 95%;
          height: 70%;
          display: flex;
          flex-wrap: wrap;
          .img-item {
            width: 48%;
            margin-right: 2%;
            margin-bottom: 2%;
            display: inline-block;
            box-sizing: border-box;
            border: 10px solid #fff;
            cursor: pointer;
          }
          // .active {
          //   border-color: #ce4c3f;
          // }
          .isCorrect {
            border-color: #214e96;
          }
        }
      }
      .trumpet-area-right{
          flex: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 100px;
        .trumpet-area {
          width: 100px;
          height: 100px;
          background-color: #cd4c3f;
          border-radius: 50%;
          img {
            width: 30%;
            &:hover {
              opacity: 0.8;
              cursor: pointer;
            }
          }
        }
        .lessononehover {
          width: 100px;
          height: 100px;
          background-color: #d1564b;
          border-radius: 50%;
          img {
            width: 30%;
            &:hover {
              opacity: 0.8;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .title-area {
      z-index: 11;
      position: absolute;
      left: 0;
      top: 10%;
      width: auto;
      height: auto;
      padding: 20px;
      background: #CD4C3F;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 22px;
      border-bottom-right-radius: 22px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
}
</style>
